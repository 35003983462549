import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { KeyStage, ListBlock } from '@lib/shared/models/content.model';
import { AlertService } from '@lib/shared/services/alert.service';
import { ContentService } from '@lib/shared/services/content.service';
import { ListService } from '@lib/shared/services/list.service';
import { StorageService } from '@lib/shared/services/storage.service';
import { ToastService } from '@lib/shared/services/toast.service';
import { ValidateSpotifyUrl, ValidateYoutubeUrl } from '@lib/shared/validators/url.validator';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-add-edit-list-block',
  templateUrl: './add-edit-list-block.component.html',
  styleUrls: ['./add-edit-list-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@Sentry.TraceClassDecorator()
export class AddEditListBlockComponent implements OnInit {
  public blockRoute: string;
  public form: UntypedFormGroup;
  public keyStages: KeyStage[] = [];
  public contentPageRef: DocumentReference;
  public enabledKeyStages: DocumentReference[];
  public tags: string[];
  public enabledIds: string[];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'bold',
        'italic',
        'underline',
        'fontName',
        'outdent',
        'indent',
        'subscript',
        'superscript',
        'strikeThrough',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertOrderedList',
        'heading',
      ],
      [
        'fontSize',
        'insertVideo',
        'insertImage',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'customClasses',
        'backgroundColor',
        'textColor',
      ],
    ],
  };

  @Input() public listBlock: ListBlock;
  @Input() private defaultOrder: number = 0;
  @Input() public isKeystage: boolean;
  @Input() private route: ActivatedRouteSnapshot;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private listService: ListService,
    private contentService: ContentService,
    private alertService: AlertService,
    private modalController: ModalController,
    private storageService: StorageService,
  ) {}

  @Sentry.TraceMethodDecorator()
  ngOnInit() {
    this.keyStages = this.route?.data?.keyStages;
    this.enabledKeyStages = this.route?.data?.enabledKeystages;
    this.enabledIds = this.enabledKeyStages ? this.enabledKeyStages.map((docRef) => docRef.id) : [];

    this.form = this.fb.group({
      title: [this.listBlock?.title ? this.listBlock.title : '', Validators.required],
      subtitle: [this.listBlock?.subtitle ? this.listBlock.subtitle : ''],
      type: [this.listBlock?.type ? this.listBlock.type : this.isKeystage ? 'keyStage' : 'list', Validators.required],
      archived: [this.listBlock?.archived === true ? true : false, [Validators.required]],
      order: [!isNaN(this.listBlock?.order) ? this.listBlock.order : this.defaultOrder, [Validators.required]],
      keyStagesIds: !this.isKeystage ? this.fb.group({}) : undefined,
      showOnTrial: [this.listBlock?.showOnTrial ? this.listBlock.showOnTrial : false],
      bgImage: [this.listBlock?.bgImage ? this.listBlock.bgImage : ''],
      lessonPlan: [this.listBlock?.lessonPlan ? this.listBlock.lessonPlan : ''],
      spotifyUrl: [this.listBlock?.spotifyUrl ? this.listBlock.spotifyUrl : '', ValidateSpotifyUrl],
      youtubeUrl: [this.listBlock?.youtubeUrl ? this.listBlock.youtubeUrl : '', ValidateYoutubeUrl],
      tags: [this.listBlock?.tags],
      walt: [this.listBlock?.walt ? this.listBlock.walt : ''],
      wilf: [this.listBlock?.wilf ? this.listBlock.wilf : ''],
    });

    this.tags = this?.listBlock?.tags ? this.listBlock.tags : [];
    this.addKeyStages();
  }

  onTagsEvent(tags: string[]) {
    this.form.get('tags').setValue(tags);
    this.tags = tags;
  }

  async bgUpload(event: any) {
    try {
      const toast = await this.toastService.showDissmissableToast(
        'Uploading image...',
        'cloud-upload-outline',
        'primary',
      );
      const bgImage = await this.storageService.bgUpload(event.target.files[0]);
      this.form.patchValue({
        bgImage,
      });
      toast.dismiss();
      this.toastService.showAutoDissmissableErrorToast('Image uploaded', 'checkbox-outline', 'success');
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast(`Unable to upload image. Please try again.`);
    }
  }

  async lessonPlanUpload(event: any) {
    try {
      const toast = await this.toastService.showDissmissableToast(
        'Uploading lesson plan...',
        'cloud-upload-outline',
        'primary',
      );
      const lessonPlan = await this.storageService.lessonPlanUpload(event.target.files[0]);
      this.form.patchValue({
        lessonPlan,
      });
      toast.dismiss();
      this.toastService.showAutoDissmissableErrorToast('Lesson plan uploaded', 'checkbox-outline', 'success');
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast(`Unable to upload lesson plan. Please try again.`);
    }
  }

  checkChk(group: FormGroup) {
    return Object.keys(group.controls).some((x) => group.controls[x].value === true) ? null : { noKeystages: true };
  }

  async addContentPage() {
    try {
      await this.contentService.addContentPage(this.route).then((docRef) => {
        this.contentPageRef = docRef;
        this.form.value.type = 'content';
        this.onSubmit();
      });
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast(`There has been an error. Please try again.`);
    }
  }

  public async onSubmit(archive: boolean = false): Promise<void> {
    try {
      if (this.form.valid || archive) {
        let contentPage = null;
        if (!this.listBlock && this.form.value.type == 'content') {
          contentPage = this.contentPageRef;
        }

        const keyStages = await Promise.all(
          Object.keys(this.form.value.keyStagesIds ? this.form.value.keyStagesIds : {})
            .filter((keystageId) => this.form.value.keyStagesIds[keystageId])
            .map(async (keystageId) => await this.listService.getKeystageRef(keystageId, this.route)),
        );

        delete this.form.value.keyStagesIds;

        if (archive) {
          this.form.value.archived = true;
        }

        const formValue = {
          ...this.form.value,
          keyStages,
        };

        if (this.listBlock) {
          await this.listService.updateListBlock(this.listBlock.id, formValue, this.route, this.isKeystage);
          if (archive) {
            this.toastService.showAutoDissmissableErrorToast('Deleted', 'checkbox-outline', 'success');
          } else {
            this.toastService.showAutoDissmissableErrorToast('Saved', 'checkbox-outline', 'success');
          }
        } else {
          formValue.contentPage = contentPage;
          await this.listService.addListBlock(formValue, this.route, this.isKeystage);
          this.toastService.showAutoDissmissableErrorToast('Added', 'checkbox-outline', 'success');
        }
        this.dismissModal();
      }
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast(`Unable to ${this.listBlock ? 'update' : 'add'}. Please try again.`);
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  getKeystageTitle(id: string) {
    return this.keyStages.find((keystage) => keystage.id == id).title;
  }

  async archiveListBlock() {
    const sure = await this.alertService.areYouSureAlert(
      'This will delete the block permanently, and any child items (list or content pages) will not be accessible.',
    );
    if (sure) {
      try {
        if (!this.listBlock) throw new Error('There has been an error. Please try again.');
        this.onSubmit(true);
      } catch (error) {
        console.error(error);
        this.toastService.showDissmissableErrorToast(`There has been an error. Please try again.`);
      }
    }
  }

  addKeyStages() {
    const checkboxes = <FormGroup>this.form.get('keyStagesIds');
    if (this.keyStages && checkboxes && !this.isKeystage) {
      checkboxes.addValidators(this.checkChk);
      this.keyStages.map((keystage: KeyStage) =>
        checkboxes.addControl(
          keystage.id,
          this.fb.control(
            this.listBlock?.keyStages.map((keyStage: DocumentReference) => keyStage.id).includes(keystage.id),
          ),
        ),
      );
    }
  }
}
