import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { Tags } from '../models/tags.model';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private afs: AngularFirestore) {}

  async getTags(): Promise<Tags> {
    return await firstValueFrom(this.afs.doc<Tags>('global/tags').valueChanges({ idField: 'id' }));
  }

  async addNewLessonTag(tag: string) {
    const tagsDoc = this.afs.doc<Tags>(`global/tags`);
    let tags = await this.getTags();
    let newTags;
    if (tags?.lessonTags) {
      tags.lessonTags.push(tag);
      newTags = tags.lessonTags;
    } else {
      newTags = [tag];
    }
    await tagsDoc.set(
      {
        lessonTags: newTags,
      },
      { merge: true }
    );
  }
}
