// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags {
  overflow: visible;
  z-index: 10;
}

.tag-search {
  position: relative;
}
.tag-search__list {
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  max-height: 150px;
}
.tag-search__list ion-item {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./lib/shared/components/add-edit-tags/add-edit-tags.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,+GAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACI;EACE,eAAA;AACN","sourcesContent":[".tags {\n  overflow: visible;\n  z-index: 10;\n}\n\n.tag-search {\n  position: relative;\n\n  &__list {\n    background-color: white;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 100%;\n    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);\n    overflow-y: auto;\n    max-height: 150px;\n\n    ion-item {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
