import { Pipe, PipeTransform } from '@angular/core';
import { Sport } from '../models/sport.model';

export const yearMap: any = {
  eyfs: 'eyfs',
  year1: 'ks1',
  year2: 'ks1',
  year3: 'ks2lower',
  year4: 'ks2lower',
  year5: 'ks2higher',
  year6: 'ks2higher',
};

@Pipe({ name: 'keystage' })
export class KeystagePipe implements PipeTransform {
  public transform(items: Sport[], yearname: string) {
    if (items !== undefined) {
      return items.filter((item: Sport) => item[yearMap[yearname]] === true);
    }
  }
}
