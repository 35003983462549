import { Component, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import Hls from "hls.js";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: [
    './video-player.component.scss'
  ],
})

export class VideoPlayerComponent implements AfterViewInit {
  @Input() videoSrc: string;
  @ViewChild('video') video: ElementRef<HTMLMediaElement>;

  private videoPlayer: HTMLMediaElement;

  constructor() {}

  ngAfterViewInit(): void {
      this.videoPlayer = this.video.nativeElement;
      this.initVideoPlayer(this.videoSrc);
  }

  private initVideoPlayer(videoSrc: string): void {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(this.videoPlayer);
    } else if (this.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
      this.videoPlayer.src = videoSrc;
    }
  }
}
