import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@lib/core';
import { ListBlock } from '@lib/shared/models/content.model';
import { AlertService } from '@lib/shared/services/alert.service';
import * as base64 from 'base-64';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-list-block',
  templateUrl: './list-block.component.html',
  styleUrls: ['./list-block.component.scss'],
})
@Sentry.TraceClassDecorator()
export class ListBlockComponent implements OnInit {
  @Output() public onArchiveBlock = new EventEmitter();
  @Output() public onEditBlock = new EventEmitter();
  @Input() public listBlock: ListBlock;
  @Input() public showEditButton = false;
  @Input() public showArchiveButton = false;
  @Input() public listTitle: string = '';
  @Input() public enableBlockRoute = true;
  @Input() public isAdminPage = false;

  public blockRoute: string;
  public blockTitleEncoded: string;
  public lessonPlanEncoded: string;
  public spotifyUrlEncoded: string;
  public youtubeUrlEncoded: string;

  constructor(
    public credentialsService: CredentialsService,
    private alertService: AlertService,
    private router: Router,
  ) {}

  @Sentry.TraceMethodDecorator()
  ngOnInit() {
    if (this.listBlock) {
      this.blockTitleEncoded = base64.encode(this.listBlock?.title);
      this.lessonPlanEncoded = base64.encode(this.listBlock?.lessonPlan);
      this.spotifyUrlEncoded = base64.encode(this.listBlock?.spotifyUrl);
      this.youtubeUrlEncoded = base64.encode(this.listBlock?.youtubeUrl);

      if (this.listBlock?.type == 'list') {
        this.blockRoute = 'list/' + this.listBlock.id;
      } else if (this.listBlock?.type == 'content') {
        this.blockRoute = 'c/' + this.listBlock?.contentPage?.id;
      } else {
        //must be type keyStage
        this.blockRoute = this.listBlock.id;
      }
    }
  }

  editBlockClick() {
    this.onEditBlock.emit();
  }

  archiveBlockClick() {
    this.onArchiveBlock.emit();
  }

  routeOrShowTrial() {
    if (!this.enableBlockRoute) {
      return;
    }
    this.alertService.isTrial(
      () =>
        this.router.navigate([...this.router.url.split('?')[0].split('/'), ...this.blockRoute.split('/')], {
          queryParams: {
            title: this.blockTitleEncoded,
            lessonPlan: this.lessonPlanEncoded,
            listTitle: this.listTitle,
            spotifyUrl: this.spotifyUrlEncoded,
            youtubeUrl: this.youtubeUrlEncoded,
          },
        }),
      this.listBlock.showOnTrial,
    );
  }
}
