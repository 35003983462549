import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  constructor() {}

  public transform(gender: 'boy' | 'girl' | 'nosay') {
    switch (gender) {
      case 'boy':
        return 'Boy';
      case 'girl':
        return 'Girl';
      default:
        return 'Rather not to say';
    }
  }
}
