// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}

.login-container-wrapper {
  background-color: #fff;
  padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./lib/login/auth/auth.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,oBAAA;AACF","sourcesContent":[".logo {\n  width: 150px;\n  margin: 0 auto;\n  margin-top: 50px;\n}\n\n.login-container-wrapper {\n  background-color: #fff;\n  padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
