import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private afStorage: AngularFireStorage, private afs: AngularFirestore) {}

  public async upload(filename: string, file: File): Promise<UploadTaskSnapshot> {
    return await this.afStorage.upload(filename, file);
  }

  async bgUpload(file: any) {
    const filename = 'session-plan-images/' + this.afs.createId() + '.jpg';
    await this.upload(filename, file);
    return `https://firebasestorage.googleapis.com/v0/b/ekc-app.appspot.com/o/${encodeURIComponent(
      filename
    )}?alt=media`;
  }

  async lessonPlanUpload(file: any) {
    const filename = '/session-plans/' + this.afs.createId() + '.pdf';
    await this.upload(filename, file);
    return filename;
  }

  async openResourceFile(lessonPlan: string) {
    return await firstValueFrom(this.afStorage.ref(lessonPlan).getDownloadURL());
  }
}
