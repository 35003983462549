import { AbstractControl } from '@angular/forms';

const checkUrl = (control: AbstractControl, expression: RegExp) => {
  if (control.value.length > 0 && !control.value.match(expression)) {
    return { urlValid: true };
  }
  return null;
};
export function ValidateYoutubeUrl(control: AbstractControl) {
  const youTubeRegex = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/gi;

  return checkUrl(control, youTubeRegex);
}

export function ValidateSpotifyUrl(control: AbstractControl) {
  const spotifyRegex = /^(https?\:\/\/)?((open\.)?spotify\.com\/playlist)\/.+$/gi;

  return checkUrl(control, spotifyRegex);
}
