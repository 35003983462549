import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListBlock } from '@lib/shared/models/content.model';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
@Sentry.TraceClassDecorator()
export class ListComponent {
  @Output() public onArchiveBlock = new EventEmitter<ListBlock>();
  @Output() public onEditBlock = new EventEmitter<ListBlock>();
  @Input() public listBlocks: ListBlock[];
  @Input() public showEditButtons = false;
  @Input() public showArchiveButtons = false;
  @Input() public listTitle: string;
  @Input() public enableBlockRoute = true;
  @Input() public isAdminPage = false;

  constructor() {}
}
