import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TagsService } from '@lib/shared/services/tags.service';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-add-edit-tags',
  templateUrl: './add-edit-tags.component.html',
  styleUrls: ['./add-edit-tags.component.scss'],
})
@Sentry.TraceClassDecorator()
export class AddEditTagsComponent implements OnInit {
  @Input() tags: string[] = [];
  @Output() tagsEvent = new EventEmitter<string[]>();
  @ViewChild('searchBar', { read: ElementRef }) boxRef: ElementRef;

  public allTags: string[];
  public availableTags: string[];
  public filteredTags: string[];
  public tagSearch: string;
  public showList: boolean = false;

  constructor(private tagsService: TagsService) {}

  @Sentry.TraceMethodDecorator()
  async ngOnInit() {
    this.updateTags();
  }

  async updateTags() {
    this.tagSearch = '';
    this.allTags = (await this.tagsService.getTags())?.lessonTags;
    this.availableTags = this.allTags?.filter((tag) => !this.tags?.includes(tag));
    this.filteredTags = this.availableTags;
    this.tagsEvent.emit(this.tags);
  }

  filterTags(search: any) {
    if (search.target.value) {
      this.filteredTags = this.availableTags.filter(
        (d) => d.toLowerCase().indexOf(search.target.value.toLowerCase()) > -1
      );
    } else {
      this.filteredTags = this.availableTags;
    }
  }

  removeTag(tag: string) {
    let index = this.tags.indexOf(tag);
    if (index !== -1) {
      this.tags.splice(index, 1);
    }
    this.updateTags();
  }

  addTag(tag: string) {
    let index = this.tags.indexOf(tag);
    if (index === -1) {
      this.tags.push(tag);
    }
    this.updateTags();
  }

  async addNewTag(tag: string) {
    this.tags.push(tag);
    await this.tagsService.addNewLessonTag(tag);
    this.updateTags();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideCurrentPopup(event: Event) {
    if (this.showList) {
      if (!this.boxRef.nativeElement.contains(event.target)) {
        this.showList = false;
      }
    } else if (!this.showList && this.boxRef.nativeElement.contains(event.target)) {
      this.showList = true;
    }
  }
}
