import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    array.sort((a: any, b: any) => {
      const aField = isNaN(a[field]) ? a[field] : parseInt(a[field]);
      const bField = isNaN(b[field]) ? b[field] : parseInt(b[field]);

      if (aField < bField) {
        return -1;
      } else if (aField > bField) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
