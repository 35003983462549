// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item-divider {
  color: var(--ion-color-primary);
  font-weight: 500;
}

.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}`, "",{"version":3,"sources":["webpack://./lib/login/create-account/create-licence/create-licence.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":["ion-item-divider {\n  color: var(--ion-color-primary);\n  font-weight: 500;\n}\n\n.error-message {\n  display: block;\n  padding-top: 10px;\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
