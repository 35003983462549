// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-note {
  display: block;
}

ion-checkbox.disabled,
ion-item.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

ion-item.disabled {
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./lib/shared/components/add-edit-list-block/add-edit-list-block.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;;EAEE,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":["ion-note {\n  display: block;\n}\n\nion-checkbox.disabled,\nion-item.disabled {\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\nion-item.disabled {\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
