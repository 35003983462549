// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid,
ion-row {
  height: 100%;
}

.login-button-container {
  margin-top: 20px;
  padding: 0 20px 20px;
}

.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}

.width-100 {
  width: 100%;
}

.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}

.logout-link {
  cursor: pointer;
  color: #494f91;
  text-decoration: underline;
}

.login-spinner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotten-password {
  text-align: center;
}
.forgotten-password a {
  margin: 0 auto;
}

.version-number {
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./lib/login/login.component.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,cAAA;AACJ;;AAGA;EACE,sBAAA;AAAF","sourcesContent":["ion-grid,\nion-row {\n  height: 100%;\n}\n\n.login-button-container {\n  margin-top: 20px;\n  padding: 0 20px 20px;\n}\n\n.error-message {\n  display: block;\n  padding-top: 10px;\n  padding-left: 20px;\n}\n\n.width-100 {\n  width: 100%;\n}\n\n.logo {\n  width: 150px;\n  margin: 0 auto;\n  margin-top: 50px;\n}\n\n.logout-link {\n  cursor: pointer;\n  color: #494f91;\n  text-decoration: underline;\n}\n\n.login-spinner-wrapper {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  background-color: #fff;\n  z-index: 10;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.forgotten-password {\n  text-align: center;\n\n  a {\n    margin: 0 auto;\n  }\n}\n\n.version-number {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
