// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-block {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: var(--ion-color-primary);
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .icon-block {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.icon-block__icon {
  font-size: 26px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 20px;
  height: auto;
}
@media screen and (min-width: 1200px) {
  .icon-block__icon {
    height: 80px;
    width: auto;
    max-width: 80px;
    margin-bottom: 24px;
    margin-right: 0;
  }
}
.icon-block__text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
@media screen and (min-width: 1200px) {
  .icon-block__text {
    width: auto;
  }
}
.icon-block__text ion-icon {
  font-size: 26px;
}
@media screen and (min-width: 1200px) {
  .icon-block__text ion-icon {
    font-size: 18px;
    margin-left: 4pxpx;
  }
}`, "",{"version":3,"sources":["webpack://./lib/shared/components/icon-block/icon-block.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;EACA,0CAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EAVF;IAWI,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,uBAAA;IACA,YAAA;EAEF;AACF;AAAE;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAEJ;AAAI;EARF;IASI,YAAA;IACA,WAAA;IACA,eAAA;IACA,mBAAA;IACA,eAAA;EAGJ;AACF;AAAE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AAEJ;AAAI;EAPF;IAQI,WAAA;EAGJ;AACF;AADI;EACE,eAAA;AAGN;AADM;EAHF;IAII,eAAA;IACA,kBAAA;EAIN;AACF","sourcesContent":[".icon-block {\n  display: flex;\n  width: 100%;\n  margin: 0;\n  padding: 15px;\n  background-color: var(--ion-color-primary);\n  color: #fff;\n  border-radius: 8px;\n  cursor: pointer;\n\n  @media screen and (min-width: 1200px) {\n    padding: 30px;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n  }\n\n  &__icon {\n    font-size: 26px;\n    margin-right: 15px;\n    display: flex;\n    align-items: center;\n    width: 20px;\n    height: auto;\n\n    @media screen and (min-width: 1200px) {\n      height: 80px;\n      width: auto;\n      max-width: 80px;\n      margin-bottom: 24px;\n      margin-right: 0;\n    }\n  }\n\n  &__text {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 18px;\n\n    @media screen and (min-width: 1200px) {\n      width: auto;\n    }\n\n    ion-icon {\n      font-size: 26px;\n\n      @media screen and (min-width: 1200px) {\n        font-size: 18px;\n        margin-left: 4pxpx;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
