// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}

.login-container-wrapper {
  background-color: #fff;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.login-spinner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./lib/login/verify-email/verify-email.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,oBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".logo {\n  width: 150px;\n  margin: 0 auto;\n  margin-top: 50px;\n}\n\n.login-container-wrapper {\n  background-color: #fff;\n  padding-bottom: 1rem;\n  padding-top: 1rem;\n}\n\n.login-spinner-wrapper {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  background-color: #fff;\n  z-index: 10;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
