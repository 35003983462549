// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  color: var(--ion-color-primary);
}

h2 {
  margin-left: 1rem;
  font-size: 1.2rem;
}

.header {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./lib/settings/licence/licence.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF","sourcesContent":["ion-card {\n  color: var(--ion-color-primary);\n}\n\nh2 {\n  margin-left: 1rem;\n  font-size: 1.2rem;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
