// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-tab-bar {
  background-color: var(--ion-color-primary-shade);
  height: 60px;
}

.bar-logo {
  width: 75%;
  padding: 10px 0;
}
@media screen and (max-width: 767px) {
  .bar-logo {
    display: none;
  }
}

ion-tab-button {
  background-color: var(--ion-color-primary-shade);
  transition: 0.3s all;
}
ion-tab-button ion-label {
  color: #fff;
  font-size: 10px;
}
ion-tab-button svg {
  color: #fff;
  width: 35px;
  height: 35px;
}
ion-tab-button.tab-selected {
  background-color: var(--ion-color-primary);
}
ion-tab-button.tab-selected svg {
  width: 35px;
  height: 35px;
}

@media (min-width: 768px) {
  ion-tabs {
    flex-direction: row-reverse;
  }
  ion-tab-bar {
    height: 100%;
    width: 90px;
    flex-direction: column;
    justify-content: flex-start;
    border-top: none;
    border-right: var(--border);
  }
  ion-tab-button {
    width: 100%;
    height: 90px;
    flex: 0 1 auto;
  }
}
.icon {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./apps/outdoorPro/src/app/shell/shell.component.scss"],"names":[],"mappings":"AAGA;EACE,gDAAA;EACA,YAAA;AAFF;;AAKA;EACE,UAAA;EACA,eAAA;AAFF;AAIE;EAJF;IAKI,aAAA;EADF;AACF;;AAIA;EACE,gDAAA;EACA,oBAAA;AADF;AAEE;EACE,WAAA;EACA,eAAA;AAAJ;AAGE;EACE,WAAA;EACA,WAAA;EACA,YAAA;AADJ;AAIE;EACE,0CAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;;AAOA;EACE;IACE,2BAAA;EAJF;EAOA;IACE,YAAA;IACA,WAhDS;IAiDT,sBAAA;IACA,2BAAA;IACA,gBAAA;IACA,2BAAA;EALF;EAQA;IACE,WAAA;IACA,YAzDS;IA0DT,cAAA;EANF;AACF;AASA;EACE,YAAA;AAPF","sourcesContent":["$tabs-width: 90px;\n$tabs-height: $tabs-width;\n\nion-tab-bar {\n  background-color: var(--ion-color-primary-shade);\n  height: 60px;\n}\n\n.bar-logo {\n  width: 75%;\n  padding: 10px 0;\n\n  @media screen and (max-width: 767px) {\n    display: none;\n  }\n}\n\nion-tab-button {\n  background-color: var(--ion-color-primary-shade);\n  transition: 0.3s all;\n  ion-label {\n    color: #fff;\n    font-size: 10px;\n  }\n\n  svg {\n    color: #fff;\n    width: 35px;\n    height: 35px;\n  }\n\n  &.tab-selected {\n    background-color: var(--ion-color-primary);\n\n    svg {\n      width: 35px;\n      height: 35px;\n    }\n  }\n}\n\n@media (min-width: 768px) {\n  ion-tabs {\n    flex-direction: row-reverse;\n  }\n\n  ion-tab-bar {\n    height: 100%;\n    width: $tabs-width;\n    flex-direction: column;\n    justify-content: flex-start;\n    border-top: none;\n    border-right: var(--border);\n  }\n\n  ion-tab-button {\n    width: 100%;\n    height: $tabs-height;\n    flex: 0 1 auto;\n  }\n}\n\n.icon {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
