import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'sani',
})
export class SaniPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(html: string) {
    const content = DOMPurify.sanitize(html);
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
