// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  color: var(--ion-color-primary);
}

h2 {
  margin-left: 1rem;
  font-size: 1.2rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.loading-text {
  position: absolute;
  top: 0;
  padding-top: 2rem;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
}

.user-list {
  min-height: 100px;
  padding-left: 0;
  padding-right: 0;
}

.user-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.user-wrapper ion-label {
  width: 100%;
  white-space: normal;
}
.user-wrapper .button-wrap {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .user-wrapper {
    flex-wrap: nowrap;
  }
  .user-wrapper .button-wrap {
    flex-wrap: nowrap;
  }
}`, "",{"version":3,"sources":["webpack://./lib/settings/licence/licence-users/licence-users.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,iBAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,mBAAA;AACJ;AAEE;EACE,iBAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;AAAJ;AAGE;EAjBF;IAkBI,iBAAA;EAAF;EAEE;IACE,iBAAA;EAAJ;AACF","sourcesContent":["ion-card {\n  color: var(--ion-color-primary);\n}\n\nh2 {\n  margin-left: 1rem;\n  font-size: 1.2rem;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.loading-text {\n  position: absolute;\n  top: 0;\n  padding-top: 2rem;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  text-align: center;\n  z-index: 2;\n  background-color: white;\n}\n\n.user-list {\n  min-height: 100px;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.user-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n\n  ion-label {\n    width: 100%;\n    white-space: normal;\n  }\n\n  .button-wrap {\n    text-align: right;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-end;\n  }\n\n  @media screen and (min-width: 768px) {\n    flex-wrap: nowrap;\n\n    .button-wrap {\n      flex-wrap: nowrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
