// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  width: 150px;
  margin: 0 auto;
  margin-top: 50px;
}

.error-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
}`, "",{"version":3,"sources":["webpack://./lib/login/create-account/create-user/create-user.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".logo {\n  width: 150px;\n  margin: 0 auto;\n  margin-top: 50px;\n}\n\n.error-message {\n  display: block;\n  padding-top: 10px;\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
