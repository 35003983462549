import { Pipe, PipeTransform } from '@angular/core';
import { Sport } from '../models/sport.model';

@Pipe({ name: 'archived' })
export class ArchivedPipe implements PipeTransform {
  public transform(items: Sport[]) {
    if (items !== undefined) {
      return items.filter((item: Sport) => !item.archived);
    }
  }
}
