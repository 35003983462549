import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  public transform(value: any, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter((item: any) => {
      return keys.split(',').some((key) => {
        const keys = key.split('.');
        if (keys.length) {
          if (keys.length === 3) {
            return item[keys[1]].hasOwnProperty(keys[2]) && item[keys[1]][keys[2]].search(new RegExp(term, 'i')) > -1;
          } else if (keys.length === 2) {
            return item.hasOwnProperty(keys[1]) && item[keys[1]].search(new RegExp(term, 'i')) > -1;
          }
        } else {
          return item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]);
        }
      });
    });
  }
}
