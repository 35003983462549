import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sessionsearch',
})
export class SessionSearchPipe implements PipeTransform {
  public transform(value: any, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter((item: any) => {
      return keys.split(',').some((key) => {
        const keys = key.split('.');
        if (keys[1] == 'tags') {
          return this.checkIfSearchTermInTags(item, term);
        } else {
          return this.checkIfSearchTermInAnyKeys(item, keys, term);
        }
      });
    });
  }

  checkIfSearchTermInTags(item: { tags?: string[] }, term: string) {
    return item.tags?.filter((tag: string) => tag.search(new RegExp(term, 'i')) > -1).length;
  }

  checkIfSearchTermInAnyKeys(item: any, keys: string[], term: string) {
    return item.hasOwnProperty(keys[1]) && item[keys[1]].search(new RegExp(term, 'i')) > -1;
  }
}
